import React from 'react';

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="282.824" height="73.746" viewBox="0 0 282.824 73.746">
    <defs>
      <style>
        {
          '.cls-1,.cls-4{fill: none}.cls-2{clip-path:url(#clip-path)}.cls-3{fill:#2b2e34}.cls-4{stroke:#2b2e34;stroke-width:.76px}.cls-5{fill:#96253c}'
        }
      </style>
      <clipPath id="clip-path">
        <path id="Pfad_98" d="M0 0h282.824v-73.746H0z" className="cls-1" data-name="Pfad 98" />
      </clipPath>
    </defs>
    <g id="Gruppe_75" className="cls-2" data-name="Gruppe 75" transform="translate(0 73.746)">
      <g id="Gruppe_49" data-name="Gruppe 49" transform="translate(89.923 -45.928)">
        <path
          id="Pfad_70"
          d="M0 0h3.463C5.609 0 8.4-.075 8.4-2.974c0-2.673-2.409-3.087-4.555-3.087H0zm-5.873-11.03H4.442c5.459 0 10.051 1.882 10.051 8.094 0 3.727-1.995 6.626-5.76 7.3l6.777 11.259H8.47L2.9 4.969H0v10.654h-5.873z"
          className="cls-3"
          data-name="Pfad 70"
        />
      </g>
      <g id="Gruppe_50" data-name="Gruppe 50" transform="translate(120.271 -41.524)">
        <path
          id="Pfad_71"
          d="M0 0a3.384 3.384 0 0 0-3.463-3.463A3.768 3.768 0 0 0-7.454 0zm4.781 8.056a9.938 9.938 0 0 1-7.717 3.614c-5.647 0-10.165-3.764-10.165-9.6s4.518-9.6 10.165-9.6c5.27 0 8.583 3.764 8.583 9.6v1.77h-13.1A4.219 4.219 0 0 0-3.2 7.379 4.82 4.82 0 0 0 .828 5.158z"
          className="cls-3"
          data-name="Pfad 71"
        />
      </g>
      <g id="Gruppe_51" data-name="Gruppe 51" transform="translate(129.349 -48.601)">
        <path
          id="Pfad_72"
          d="M0 0h5.421v2.484H5.5a5.934 5.934 0 0 1 5.342-2.936c5.722 0 6.776 3.765 6.776 8.659V18.3h-5.646V9.336c0-2.108-.039-4.819-2.937-4.819-2.937 0-3.388 2.3-3.388 4.669V18.3H0z"
          className="cls-3"
          data-name="Pfad 72"
        />
      </g>
      <g id="Gruppe_52" data-name="Gruppe 52" transform="translate(163.498 -41.524)">
        <path
          id="Pfad_73"
          d="M0 0a3.384 3.384 0 0 0-3.464-3.463A3.768 3.768 0 0 0-7.454 0zm-3.689-15.435H3.99l-6.513 5.421h-4.969zm8.47 23.491a9.94 9.94 0 0 1-7.717 3.614c-5.646 0-10.165-3.764-10.165-9.6s4.519-9.6 10.165-9.6c5.271 0 8.583 3.764 8.583 9.6v1.77h-13.1A4.219 4.219 0 0 0-3.2 7.379 4.821 4.821 0 0 0 .828 5.158z"
          className="cls-3"
          data-name="Pfad 73"
        />
      </g>
      <g id="Gruppe_53" data-name="Gruppe 53" transform="translate(180.897 -56.959)">
        <path
          id="Pfad_74"
          d="M0 0h5.871v11.068L16.225 0H23.9L12.045 12.386 24.92 26.653h-8.206L5.871 14v12.653H0z"
          className="cls-3"
          data-name="Pfad 74"
        />
      </g>
      <g id="Gruppe_54" data-name="Gruppe 54" transform="translate(219.677 -41.524)">
        <path
          id="Pfad_75"
          d="M0 0a3.383 3.383 0 0 0-3.463-3.463A3.766 3.766 0 0 0-7.453 0zm4.782 8.056a9.94 9.94 0 0 1-7.718 3.614c-5.646 0-10.163-3.764-10.163-9.6s4.517-9.6 10.163-9.6c5.271 0 8.583 3.764 8.583 9.6v1.77h-13.1A4.219 4.219 0 0 0-3.2 7.379 4.818 4.818 0 0 0 .828 5.158z"
          className="cls-3"
          data-name="Pfad 75"
        />
      </g>
      <path id="Pfad_76" d="M228.83-58.766h5.647v28.46h-5.647z" className="cls-3" data-name="Pfad 76" />
      <path id="Pfad_77" d="M238.62-58.766h5.647v28.46h-5.647z" className="cls-3" data-name="Pfad 77" />
      <g id="Gruppe_55" data-name="Gruppe 55" transform="translate(260.871 -41.524)">
        <path
          id="Pfad_78"
          d="M0 0a3.384 3.384 0 0 0-3.463-3.463A3.767 3.767 0 0 0-7.454 0zm4.782 8.056a9.944 9.944 0 0 1-7.719 3.614c-5.647 0-10.164-3.764-10.164-9.6s4.517-9.6 10.164-9.6c5.272 0 8.584 3.764 8.584 9.6v1.77h-13.1A4.218 4.218 0 0 0-3.2 7.379 4.821 4.821 0 0 0 .828 5.158z"
          className="cls-3"
          data-name="Pfad 78"
        />
      </g>
      <g id="Gruppe_56" data-name="Gruppe 56" transform="translate(269.947 -48.601)">
        <path
          id="Pfad_79"
          d="M0 0h5.648v2.937h.076C6.928.715 8.508-.452 10.994-.452a7.407 7.407 0 0 1 1.882.189v5.157a8.692 8.692 0 0 0-2.41-.377c-4.291 0-4.818 2.483-4.818 6.137V18.3H0z"
          className="cls-3"
          data-name="Pfad 79"
        />
      </g>
      <g id="Gruppe_57" data-name="Gruppe 57" transform="translate(83.342 -17.797)">
        <path
          id="Pfad_80"
          d="M0 0h2.844l5.461 12.628h.045L13.856 0h2.776v16.109h-1.911V2.594h-.046L8.9 16.109H7.735L1.956 2.594h-.045v13.515H0z"
          className="cls-3"
          data-name="Pfad 80"
        />
      </g>
      <g id="Gruppe_58" data-name="Gruppe 58" transform="translate(110.75 -15.339)">
        <path
          id="Pfad_81"
          d="M0 0h-.046L-3.39 7.918h6.575zm-.865-2.458h1.8l6.846 16.109H5.552L3.913 9.692h-8.054L-5.8 13.651h-2.163z"
          className="cls-3"
          data-name="Pfad 81"
        />
      </g>
      <g id="Gruppe_59" data-name="Gruppe 59" transform="translate(121.346 -17.797)">
        <path id="Pfad_82" d="M0 0h1.911v14.334h7.373v1.775H0z" className="cls-3" data-name="Pfad 82" />
      </g>
      <g id="Gruppe_60" data-name="Gruppe 60" transform="translate(133.4 -17.797)">
        <path
          id="Pfad_83"
          d="M0 0h10.125v1.775H1.911v5.051h7.668V8.6H1.911v5.733h8.624v1.775H0z"
          className="cls-3"
          data-name="Pfad 83"
        />
      </g>
      <g id="Gruppe_61" data-name="Gruppe 61" transform="translate(149.48 -10.698)">
        <path
          id="Pfad_84"
          d="M0 0h1.706c2.117 0 4.87 0 4.87-2.662 0-2.321-2.024-2.663-3.891-2.663H0zm-1.911-7.1h4.117c3.118 0 6.418.455 6.418 4.437C8.624-.319 7.1 1.205 4.6 1.592L9.169 9.01H6.826l-4.37-7.235H0V9.01h-1.911z"
          className="cls-3"
          data-name="Pfad 84"
        />
      </g>
      <g id="Gruppe_62" data-name="Gruppe 62" transform="translate(175.846 -2.78)">
        <path
          id="Pfad_85"
          d="M0 0a12.414 12.414 0 0 1-6.031 1.5c-5.005 0-8.6-3.413-8.6-8.464a8.3 8.3 0 0 1 8.67-8.465A7.724 7.724 0 0 1-.274-13.31l-1.433 1.5A6.048 6.048 0 0 0-6.1-13.652a6.393 6.393 0 0 0-6.484 6.69A6.422 6.422 0 0 0-5.916-.273a9.457 9.457 0 0 0 4-.933v-4.937h-3.705v-1.775H0z"
          className="cls-3"
          data-name="Pfad 85"
        />
      </g>
      <g id="Gruppe_63" data-name="Gruppe 63" transform="translate(180.524 -17.797)">
        <path
          id="Pfad_86"
          d="M0 0h10.126v1.775H1.911v5.051h7.668V8.6H1.911v5.733h8.623v1.775H0z"
          className="cls-3"
          data-name="Pfad 86"
        />
      </g>
      <g id="Gruppe_64" data-name="Gruppe 64" transform="translate(202.338 -14.953)">
        <path
          id="Pfad_87"
          d="M0 0a3.345 3.345 0 0 0-2.936-1.479c-1.682 0-3.435.8-3.435 2.708 0 1.479.819 2.139 3.39 2.958 2.5.8 4.825 1.615 4.825 4.778 0 3.208-2.754 4.71-5.667 4.71a5.842 5.842 0 0 1-4.96-2.23l1.639-1.345a3.9 3.9 0 0 0 3.434 1.8c1.639 0 3.51-.956 3.51-2.8 0-1.979-1.344-2.32-4.21-3.276-2.276-.751-4.005-1.752-4.005-4.436 0-3.1 2.661-4.643 5.482-4.643a5.523 5.523 0 0 1 4.502 1.89z"
          className="cls-3"
          data-name="Pfad 87"
        />
      </g>
      <g id="Gruppe_65" data-name="Gruppe 65" transform="translate(219.307 -14.475)">
        <path
          id="Pfad_88"
          d="M0 0a4.956 4.956 0 0 0-4.028-1.957c-3.6 0-6.143 3.163-6.143 6.69 0 3.731 2.526 6.689 6.143 6.689A5.676 5.676 0 0 0 .637 9.169l1.432 1.207a7.153 7.153 0 0 1-6.1 2.821 8.139 8.139 0 0 1-8.19-8.464 8.137 8.137 0 0 1 8.19-8.465 6.975 6.975 0 0 1 5.692 2.526z"
          className="cls-3"
          data-name="Pfad 88"
        />
      </g>
      <g id="Gruppe_66" data-name="Gruppe 66" transform="translate(224.714 -17.797)">
        <path
          id="Pfad_89"
          d="M0 0h1.911v6.826h8.6V0h1.911v16.109h-1.91V8.6h-8.6v7.508H0z"
          className="cls-3"
          data-name="Pfad 89"
        />
      </g>
      <g id="Gruppe_67" data-name="Gruppe 67" transform="translate(251.305 -20.436)">
        <path
          id="Pfad_90"
          d="M0 0a1.223 1.223 0 0 1-1.229 1.228A1.223 1.223 0 0 1-2.458 0a1.239 1.239 0 0 1 1.229-1.229A1.239 1.239 0 0 1 0 0m-3.39 5.1h-.046l-3.345 7.918H-.2zM-4.505 0a1.222 1.222 0 0 1-1.229 1.228A1.223 1.223 0 0 1-6.963 0a1.239 1.239 0 0 1 1.229-1.229A1.238 1.238 0 0 1-4.505 0m.25 2.639h1.8l6.846 16.109h-2.23L.523 14.789h-8.054l-1.661 3.959h-2.162z"
          className="cls-3"
          data-name="Pfad 90"
        />
      </g>
      <g id="Gruppe_68" data-name="Gruppe 68" transform="translate(258.509 -17.797)">
        <path id="Pfad_91" d="M0 0h9.989v1.775H1.913V7.1H9.42v1.774H1.913v7.235H0z" className="cls-3" data-name="Pfad 91" />
      </g>
      <g id="Gruppe_69" data-name="Gruppe 69" transform="translate(282.646 -16.022)">
        <path id="Pfad_92" d="M0 0h-5.324v14.335h-1.912V0h-5.324v-1.775H0z" className="cls-3" data-name="Pfad 92" />
      </g>
      <g id="Gruppe_70" data-name="Gruppe 70" transform="translate(13.163 -25.203)">
        <path id="Pfad_93" d="M0 0" className="cls-4" data-name="Pfad 93" />
      </g>
      <g id="Gruppe_71" data-name="Gruppe 71" transform="translate(33.23 -3.238)">
        <path
          id="Pfad_94"
          d="M0 0a26.973 26.973 0 0 1-26.971-26.972A26.972 26.972 0 0 1 0-53.945a26.973 26.973 0 0 1 26.974 26.973A26.974 26.974 0 0 1 0 0m-.863-61.495A32.367 32.367 0 0 0-33.23-29.128 32.365 32.365 0 0 0-.863 3.238 32.367 32.367 0 0 0 31.5-29.128 32.369 32.369 0 0 0-.863-61.495"
          className="cls-5"
          data-name="Pfad 94"
        />
      </g>
      <g id="Gruppe_72" data-name="Gruppe 72" transform="translate(48.182 -43.273)">
        <path
          id="Pfad_95"
          d="M0 0a3.118 3.118 0 0 1 .838 4.327l-2.352 3.508a3.113 3.113 0 0 1-4.319.865L-15.841 2a3.117 3.117 0 0 1-.837-4.329l2.351-3.5a3.116 3.116 0 0 1 4.32-.868z"
          className="cls-3"
          data-name="Pfad 95"
        />
      </g>
      <g id="Gruppe_73" data-name="Gruppe 73" transform="translate(40.287 -25.913)">
        <path id="Pfad_96" d="M0 0l6.489-9.678-15.168-10.16-6.484 9.674z" className="cls-5" data-name="Pfad 96" />
      </g>
      <g id="Gruppe_74" data-name="Gruppe 74" transform="translate(45.58 -44.493)">
        <path
          id="Pfad_97"
          d="M0 0l16.459-24.571c2.3-3.437-3.24-6.634-5.522-3.227L-5.522-3.227C-7.824.21-2.282 3.407 0 0"
          className="cls-3"
          data-name="Pfad 97"
        />
      </g>
    </g>
  </svg>
);
