import React from 'react';
import { Link } from 'gatsby';

import { useStyles } from './styles';

const Footer = () => {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <Link to="/imprint/" className={classes.link}>
        Impressum
      </Link>
      {' | '}
      <Link to="/policy/" className={classes.link}>
        Datenschutz
      </Link>
    </footer>
  );
};

export default Footer;
