import { createUseStyles } from 'react-jss';

import fedraSansStdBookWoff from '@/assets/fonts/FedraSansStdBook.woff';
import fedraSansStdBookWoff2 from '@/assets/fonts/FedraSansStdBook.woff2';
import fedraSansStdDemiWoff from '@/assets/fonts/FedraSansStdDemi.woff';
import fedraSansStdDemiWoff2 from '@/assets/fonts/FedraSansStdDemi.woff2';
import avenirBlackWoff from '@/assets/fonts/Avenir-Black.woff';
import avenirBlackWoff2 from '@/assets/fonts/Avenir-Black.woff2';
import avenirBookWoff from '@/assets/fonts/Avenir-Book.woff';
import avenirBookWoff2 from '@/assets/fonts/Avenir-Book.woff2';

import { colors } from '.';

const { grey, white } = colors;

const useStyles = createUseStyles({
  '@global': {
    html: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: '100%',
      minWidth: 350,
    },
    'body, #___gatsby, #gatsby-focus-wrapper': {
      flex: [1, 1, 'auto'],
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      margin: 0,
      padding: 0,
      '-webkit-tap-highlight-color': 'rgba(255, 255, 255, 0)',
    },
    body: {
      fontSmoothing: 'antialiased',
      fontFamily: 'FedraSerifAStd-Book',
      fontSize: 22,
      lineHeight: 1.45,
      color: grey[70],
      background: white,
    },
    '*': {
      '-webkit-overflow-scrolling': 'touch',
    },
  },
  '@font-face': [
    {
      fontFamily: 'FedraSerifAStd-Book',
      fontWeight: 'normal',
      fontStyle: 'normal',
      src: [`url(${fedraSansStdBookWoff}) format("woff")`, `url(${fedraSansStdBookWoff2}) format("woff2")`],
    },
    {
      fontFamily: 'FedraSerifAStd-Demi',
      fontWeight: 'normal',
      fontStyle: 'normal',
      src: [`url(${fedraSansStdDemiWoff}) format("woff")`, `url(${fedraSansStdDemiWoff2}) format("woff2")`],
    },
    {
      fontFamily: 'Avenir-Heavy',
      fontWeight: 900,
      fontStyle: 'normal',
      src: [`url(${avenirBlackWoff}) format("woff")`, `url(${avenirBlackWoff2}) format("woff2")`],
    },
    {
      fontFamily: 'Avenir-Book',
      fontWeight: 'normal',
      fontStyle: 'normal',
      src: [`url(${avenirBookWoff}) format("woff")`, `url(${avenirBookWoff2}) format("woff2")`],
    },
  ],
});

const GlobalStyles = () => {
  useStyles();

  return null;
};

export default GlobalStyles;
