const colors = {
  white: '#fff',
  black: '#000',
  blood: '#96253c',
  grey: {
    60: '#606060',
    70: '#707070',
  },
  lightBlood: 'rgba(150,37,60, .4)',
};

export { colors };
