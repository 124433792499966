import { createUseStyles } from 'react-jss';

import { colors } from '@/theme';

const { white, grey } = colors;

const text = {
  fontFamily: 'Avenir-Book',
  fontSize: 12,
  color: grey[60],
  lineHeight: 1.25,
  margin: 0,
};

export const useStyles = createUseStyles({
  header: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: white,
    minHeight: 204,
  },
  textContainer: {
    display: 'none',
  },
  text,
  link: {
    textDecoration: 'none',
  },
  boldLink: {
    ...text,
    textDecoration: 'none',
    fontFamily: 'Avenir-Heavy',
  },
  '@media (max-width: 800px)': {
    header: {
      flexDirection: 'column',
      minHeight: 169,
    },
    textContainer: {
      display: 'block',
      margin: [10, 0, 0, 65],
    },
  },
});
