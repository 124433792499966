/* eslint-disable react/prop-types */
const React = require('react');
const { JssProvider } = require('react-jss');

const GlobalStyles = require('./src/theme/GlobalStyles').default;
const Layout = require('./src/components/Layout').default;

exports.onInitialClientRender = () => {
  const ssStyles = window.document.getElementById('server-side-jss');

  if (ssStyles) ssStyles.parentNode.removeChild(ssStyles);
};

exports.wrapRootElement = ({ element }) => (
  <JssProvider id={{ minify: process.env.NODE_ENV === 'production' }}>
    <>
      <GlobalStyles />
      {element}
    </>
  </JssProvider>
);

exports.wrapPageElement = ({ element }) => <Layout>{element}</Layout>;
