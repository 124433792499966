import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';

import Logo from '@/assets/svg/Logo';

import { useStyles } from './styles';

const Header = () => {
  const classes = useStyles();
  const { dataJson } = useStaticQuery(graphql`
    {
      dataJson {
        contacts {
          address
          code
          googleNavigation
          links {
            name
            type
            url
            value
          }
        }
      }
    }
  `);
  const { address, code, googleNavigation, links } = dataJson.contacts;

  return (
    <header className={classes.header}>
      <Link to="/" aria-label="Home">
        <Logo />
      </Link>
      <div className={classes.textContainer}>
        <a href={googleNavigation} className={classes.link} rel="noopener noreferrer" target="_blank">
          <p className={classes.text}>
            {address}
            {', '}
            {code}
          </p>
        </a>
        {links.map(({ name, value, url }) => (
          <p key={value} className={classes.text}>
            {name}{' '}
            <a href={url} className={classes.boldLink}>
              {value}
            </a>
          </p>
        ))}
      </div>
    </header>
  );
};

export default Header;
