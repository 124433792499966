import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';

import Header from './Header';
import Footer from './Footer';
import SwimText from './SwimText';
import { useStyles } from './styles';

const Layout = ({ children }) => {
  const classes = useStyles();
  const { allFile } = useStaticQuery(graphql`
    {
      allFile(filter: { relativePath: { eq: "background.jpg" } }) {
        nodes {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, transformOptions: { cropFocus: CENTER, fit: COVER })
          }
        }
      }
    }
  `);

  return (
    <>
      <Header />
      <main className={classes.main}>
        <div className={classes.image}>
          <GatsbyImage image={allFile.nodes[0].childImageSharp.gatsbyImageData} alt="background.jpg" />
        </div>
        <div className={classes.container}>
          <SwimText />
          {children}
        </div>
      </main>
      <Footer />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default Layout;
