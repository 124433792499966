import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';

import { useStyles } from './styles';

const SwimText = () => {
  const classes = useStyles();
  const { dataJson } = useStaticQuery(graphql`
    {
      dataJson {
        contacts {
          name
          address
          code
          googleNavigation
          links {
            name
            type
            url
            value
          }
        }
      }
    }
  `);
  const { name, address, code, googleNavigation, links } = dataJson.contacts;

  return (
    <div className={classes.container}>
      <Link to="/" className={classes.link}>
        <p className={classes.text}>{name}</p>
      </Link>
      <a href={googleNavigation} rel="noopener noreferrer" target="_blank" className={classes.link}>
        <p className={classes.text}>{address}</p>
        <p className={classes.text}>{code}</p>
      </a>
      {links.map(({ name: titleText, type, value, url }) =>
        type === 'phone' ? (
          <p key={value} className={classes.text}>
            <span>{titleText}</span>{' '}
            <a href={url} className={classes.link}>
              {value}
            </a>
          </p>
        ) : (
          <a key={value} href={url} className={classes.link}>
            {value}
          </a>
        )
      )}
    </div>
  );
};

export default SwimText;
