import { createUseStyles } from 'react-jss';

import { colors } from '@/theme';

const { blood, white } = colors;

export const useStyles = createUseStyles({
  footer: {
    flex: [0, 0, 'auto'],
    display: 'flex',
    alignItems: 'center',
    background: white,
    minHeight: 63,
    color: blood,
    paddingLeft: '5%',
    fontSize: 22,
  },
  link: {
    color: 'inherit',
    lineHeight: 1.14,
    margin: [0, 5],
    textDecoration: 'none',
    transition: 'all .5s',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  '@media (max-width: 800px)': {
    footer: {
      background: blood,
      minHeight: 40,
      color: white,
      fontSize: 12,
    },
    link: {
      lineHeight: 1,
    },
  },
});
