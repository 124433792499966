import { createUseStyles } from 'react-jss';

import { colors } from '@/theme';

export const useStyles = createUseStyles({
  main: {
    flex: [1, 1, 'auto'],
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    background: colors.blood,
  },
  image: {
    position: 'absolute',
    width: '100%',
  },
  container: {
    flex: [1, 1, 'auto'],
    display: 'flex',
    justifyContent: 'space-between',
    margin: ['15%', '5%', '10%', '5%'],
    width: '90%',
  },
  '@media (max-width: 800px)': {
    main: {
      background: 'none',
    },
    image: {
      display: 'none',
    },
    container: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      margin: 0,
      width: '100%',
    },
  },
});
