import { createUseStyles } from 'react-jss';

import { colors } from '@/theme';

export const useStyles = createUseStyles({
  container: {
    position: 'fixed',
    bottom: '20%',
    marginRight: '10%',
    zIndex: 100,
    transition: 'top .3s cubic-bezier(.61,1.14,0,.83)',
  },
  text: {
    fontFamily: 'Avenir-Heavy',
    fontSize: 22,
    lineHeight: 1.27,
    margin: 0,
    '&>span': {
      fontFamily: 'Avenir-Book',
      fontWeight: 900,
    },
    color: colors.white,
  },
  link: {
    fontFamily: 'Avenir-Heavy',
    textDecoration: 'none',
    transition: 'all .5s',
    '&:hover': {
      textDecoration: 'underline',
    },
    color: colors.white,
  },
  '@media (max-width: 1200px)': {
    text: {
      fontSize: 20,
    },
    link: {
      fontSize: 20,
    },
  },
  '@media (max-width: 1000px)': {
    text: {
      fontSize: 18,
    },
    link: {
      fontSize: 18,
    },
  },
  '@media (max-width: 800px)': {
    container: {
      display: 'none',
    },
  },
});
